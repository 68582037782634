import {Helmet} from 'react-helmet';
import * as layoutStyles from '../components/layout/layout.module.css';

const ServerErrorPage = () => (
    <>
        <Helmet>
            <title>Server Error | AX 2.0</title>
        </Helmet>
        <div className={layoutStyles.restricted}>
            <div className={layoutStyles.textAndImage}>
                <img src='/images/lego_knight.png' alt="lego_knight" />
                <div className={layoutStyles.text}>
                    <h1>500</h1>
                    <p>
                        Sorry{' '}
                        <span role="img" aria-label="Pensive emoji">
                            😔
                        </span>{' '}
                        there is some issue on our server.<br/>
                        We already notice our developers and they are working for quick fix.<br/>
                        Please, try to reload this page later.
                    </p>
                </div>
            </div>
            <div className={layoutStyles.bottomSection}></div>
        </div>
    </>
);

export default ServerErrorPage;
